export const colors = [
  'blue',
  'green',
  'purple',
  'red',
];

export const sizes = [
  'S',
  'M',
  'L',
];

export const targets = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
];

export const lines = [
  ['A1', 'B1', 'C1'],
  ['A2', 'B2', 'C2'],
  ['A3', 'B3', 'C3'],
  ['A1', 'A2', 'A3'],
  ['B1', 'B2', 'B3'],
  ['C1', 'C2', 'C3'],
  ['A1', 'B2', 'C3'],
  ['A3', 'B2', 'C1'],
];

export const createCells = () => ({
  A1: {
    S: null,
    M: null,
    L: null,
  },
  A2: {
    S: null,
    M: null,
    L: null,
  },
  A3: {
    S: null,
    M: null,
    L: null,
  },
  B1: {
    S: null,
    M: null,
    L: null,
  },
  B2: {
    S: null,
    M: null,
    L: null,
  },
  B3: {
    S: null,
    M: null,
    L: null,
  },
  C1: {
    S: null,
    M: null,
    L: null,
  },
  C2: {
    S: null,
    M: null,
    L: null,
  },
  C3: {
    S: null,
    M: null,
    L: null,
  },
});
