import Axios from "axios";
import {MERCURE_HUB, MERCURE_JWT} from "@/vars";

const axios = Axios.create({
  headers: {
    Authorization: `Bearer ${MERCURE_JWT}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

export function subscribe(topic) {
  const url = new URL(MERCURE_HUB);
  url.searchParams.append('topic', topic);
  return new EventSource(url.toString());
}

export function publish(topic, update = null, type) {
  const payload = new URLSearchParams();
  payload.append('topic', topic);
  payload.append('data', `type=${type}&data=${update}`);
  return axios.post(MERCURE_HUB, payload.toString());
}
