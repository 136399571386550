<template>
  <circle
    cx="0"
    cy="0"
    :r="r"
    :fill="fill"
    @mouseover="toggleHover(true)"
    @mouseleave="toggleHover(false)"
    @click="$emit('choose')"
  />
</template>
<script>

  export default {
    props: {
      r: {
        type: Number,
        required: true,
      },
      fill: {
        type: String,
        default: () => '#E5E5E5',
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hoverColor: {
        type: String,
      }
    },
    data() {
      return {
        hover: false,
      }
    },
    methods: {
      toggleHover(value) {
        if (false === this.selectable || true === this.disabled) {
          return;
        }

        this.hover = value;
        this.$emit(value ? 'mouseover' : 'mouseleave');
      }
    },
    watch: {
      hover(hover) {
        if (true === this.disabled) {
          this.$el.style.cursor = 'not-allowed';

          return;
        }
        if (true === hover) {
          this.$el.style.cursor = 'pointer';
        } else {
          this.$el.style.cursor = 'inherit';
        }
      }
    }
  }
</script>

