<template>
  <svg-circle
    :r="r"
    :selectable="selectable"
    :disabled="disabled"
    @choose="$emit('choose')"
  />
</template>
<script>
  import SvgCircle from "@/components/SvgCircle";

  const rMap = {
    S: 14,
    M: 36,
    L: 50,
  };

  export default {
    components: {
      SvgCircle,
    },
    props: {
      size: {
        type: String,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        hover: false,
      }
    },
    computed: {
      r() {
        return rMap[this.size];
      },
      displayedColor() {
        if (true === this.disabled) {
          return this.disabledColor;
        }

        if (true === this.active) {
          return this.activeColor;
        }

        if (true === this.hover) {
          return this.hoverColor;
        }

        return this.color;
      },
    },
  }
</script>

