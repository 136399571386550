<template>
  <div>
    <div v-if="empty(board)">Loading...</div>

    <div v-else class="landscape:grid landscape:grid-cols-12 landscape:gap-12">
      <div class="col-span-7">
        <board v-if="isPartOfTheGame && isReadyToPlay"
               :board="board"
               :cells="cells"
               :pieces="pieces[nextColorToPlay]"
               :playing="myColors.includes(nextColorToPlay) && empty(winningSequence)"
               :winning-sequence="winningSequence"
               @pick="play"
        />
      </div>

      <div class="col-span-5 text-left px-5 pb-5 portrait:mt-5 landscape:mt-3">

        <!-- Players -->
        <h2 class="text-xl font-bold text-center border-collapse">Players</h2>
        <table class="table-auto" id="players-table">
          <tbody>
          <tr>
            <td>
              <div class="rounded-full h-8 w-8 bg-blue-600"></div>
            </td>
            <td v-if="!empty(board.players.blue)" :class="{'font-bold': 'blue' === nextColorToPlay}">
              {{ getUserByColor('blue').username }} <span v-if="getUserByColor('blue').id === userId">(you)</span>
            </td>
            <td v-else>
              <a v-if="isWaitingForPlayers" href="" @click.prevent="join('blue')">Join</a>
              <span v-else class="italic">No player</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="rounded-full h-8 w-8 bg-green-600"></div>
            </td>
            <td v-if="!empty(board.players.green)" :class="{'font-bold': 'green' === nextColorToPlay}">
              {{ getUserByColor('green').username }} <span v-if="getUserByColor('green').id === userId">(you)</span>
            </td>
            <td v-else>
              <a v-if="isWaitingForPlayers" href="" @click.prevent="join('green')">Join</a>
              <span v-else class="italic">No player</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="rounded-full h-8 w-8 bg-purple-600"></div>
            </td>
            <td v-if="!empty(board.players.purple)" :class="{'font-bold': 'purple' === nextColorToPlay}">
              {{ getUserByColor('purple').username }} <span v-if="getUserByColor('purple').id === userId">(you)</span>
            </td>
            <td v-else>
              <a v-if="isWaitingForPlayers" href="" @click.prevent="join('purple')">Join</a>
              <span v-else class="italic">No player</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="rounded-full h-8 w-8 bg-red-600"></div>
            </td>
            <td v-if="!empty(board.players.red)" :class="{'font-bold': 'red' === nextColorToPlay}">
              {{ getUserByColor('red').username }} <span v-if="getUserByColor('red').id === userId">(you)</span>
            </td>
            <td v-else>
              <a v-if="isWaitingForPlayers" href="" @click.prevent="join('red')">Join</a>
              <span v-else class="italic">No player</span>
            </td>
          </tr>
          </tbody>
        </table>

        <div v-if="!empty(winningSequence)" class="mt-5 text-center">
          <span class="text-2xl"><strong>{{ getUserByColor(winningSequence.color).username }}</strong> won the game! 🎉</span><br />
          <a
            :href="`/board/${board.next}/play`"
            class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
            type="button">
            Play again
          </a>
        </div>

        <div v-else class="mt-5 text-center">
          <button
            v-clipboard="currentLocation"
            v-clipboard:success="onLinkClipboard"
            class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            type="button">
            Invite people
          </button>
        </div>

        <!-- Pieces -->
        <div
          v-if="isPartOfTheGame"
          v-for="color in myColors"
          class="grid grid-cols-3 xl:gap-x-20 mt-5">

          <cell>
            <template #large>
              <circle-slot size="L" :selectable="false"
                           :fill="isPickable(pieces[color]['L'][3]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #medium>
              <circle-slot size="M" :selectable="false"
                           :fill="isPickable(pieces[color]['M'][3]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #small>
              <circle-slot size="S" :selectable="false"
                           :fill="isPickable(pieces[color]['S'][3]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
          </cell>

          <cell>
            <template #large>
              <circle-slot size="L" :selectable="false"
                           :fill="isPickable(pieces[color]['L'][2]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #medium>
              <circle-slot size="M" :selectable="false"
                           :fill="isPickable(pieces[color]['M'][2]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #small>
              <circle-slot size="S" :selectable="false"
                           :fill="isPickable(pieces[color]['S'][2]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
          </cell>

          <cell>
            <template #large>
              <circle-slot size="L" :selectable="false"
                           :fill="isPickable(pieces[color]['L'][1]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #medium>
              <circle-slot size="M" :selectable="false"
                           :fill="isPickable(pieces[color]['M'][1]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
            <template #small>
              <circle-slot size="S" :selectable="false"
                           :fill="isPickable(pieces[color]['S'][1]) ? getHexaCode(color) : nonPickableHexa"/>
            </template>
          </cell>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Board from "@/components/Board";
  import Cell from "@/components/Cell";
  import CircleSlot from "@/components/CircleSlot";
  import {createBoard, createBoardModule, getHexaCode, GRAY} from "@/store/board";
  import {mapState, mapGetters} from "vuex";
  import {subscribe, publish} from "@/mercure";
  import {QueryString} from 'psr7-js';

  export default {
    components: {
      Board,
      Cell,
      CircleSlot,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      igniter: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        eventSource: null,
        nonPickableHexa: GRAY,
        currentLocation: document.location.href
      }
    },
    computed: {
      ...mapState({
        board: state => state.board,
        userId: state => state.userId,
        username: state => state.username,
      }),
      ...mapGetters('board', [
        'cells',
        'isWaitingForPlayers',
        'isReadyToPlay',
        'nextColorToPlay',
        'winningSequence',
        'getUserById',
        'getUserByColor',
      ]),
      topic() {
        return `/otrio/board/${this.id}`;
      },
      isPartOfTheGame() {
        return this.userId in this.board.playerInformation;
      },
      myColors() {
        return this.getColorsByUserId(this.userId);
      },
      pieces() {
        return this.board.pieces;
      }
    },
    methods: {
      onStateUpdate(state) {
        console.log('Publishing Mercure update: ', state);
        publish(this.topic, JSON.stringify(state), 'publish_state');
      },
      async join(color) {
        const user = {
          id: this.$store.state.userId,
          username: this.$store.state.username,
        }
        await this.$store.dispatch('board/JOIN', {user, color})
      },
      getColorsByUserId(userId) {
        return this.$store.getters['board/getColorsByUserId'](userId);
      },
      getHexaCode,
      isPickable(piece) {
        return null === piece;
      },
      async play({target, size}) {
        const color = this.nextColorToPlay;
        for (const piece in this.pieces[color][size]) {
          if (Object.prototype.hasOwnProperty.call(this.pieces[color][size], piece) && null === this.pieces[color][size][piece]) {
            const move = {color, size, piece: parseInt(piece), target};
            await this.$store.dispatch('board/PLAY', move);

            return;
          }
        }

        throw Error(`Cannot play ${target} for ${color}`);
      },
      onLinkClipboard() {
        alert('Link copied to clipboard!');
      },
    },
    created() {
      const board = createBoard();
      board.id = this.id;
      const boardModule = createBoardModule(board, this.onStateUpdate);
      if (this.$store.hasModule('board')) {
        this.$store.unregisterModule('board');
      }
      this.$store.registerModule('board', boardModule);
      this.eventSource = subscribe(this.topic);
      this.eventSource.onmessage = (event) => {
        console.log('Received Mercure update: ', event.data);
        const {type, data} = (new QueryString(event.data)).getParams();
        if ('publish_state' === type) {
          this.$store.dispatch('board/UPDATE', JSON.parse(data));
        }
        if ('ask_state' === type && this.userId !== data) {
          publish(this.topic, JSON.stringify(this.board), 'publish_state');
        }
      };
      if (false === this.igniter) {
        publish(this.topic, this.userId, 'ask_state');
      }
    },
    beforeDestroy() {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
</script>
<style lang="scss" scoped>
  #players-table {
    td {
      @apply px-4 py-2;
    }
  }
</style>
