import {ulid} from 'ulid';
import Vue from "vue";
import {colors, sizes, targets, lines, createCells} from "@/store/definitions";
import empty from 'is-empty';

export const BLUE = '#2563EB';
export const GREEN = '#059669';
export const PURPLE = '#7C3AED';
export const RED = '#DC2626';
export const GRAY = '#e7e7e7';

const colorMap = {
  blue: BLUE,
  green: GREEN,
  purple: PURPLE,
  red: RED,
};

export function getHexaCode(color) {
  return colorMap[color] ?? null;
}

export function createBoard() {
  return {
    id: ulid(),
    next: ulid(),
    players: {
      blue: null,
      green: null,
      purple: null,
      red: null,
    },
    pieces: {
      blue: {
        S: {
          1: null,
          2: null,
          3: null,
        },
        M: {
          1: null,
          2: null,
          3: null,
        },
        L: {
          1: null,
          2: null,
          3: null,
        },
      },
      green: {
        S: {
          1: null,
          2: null,
          3: null,
        },
        M: {
          1: null,
          2: null,
          3: null,
        },
        L: {
          1: null,
          2: null,
          3: null,
        },
      },
      purple: {
        S: {
          1: null,
          2: null,
          3: null,
        },
        M: {
          1: null,
          2: null,
          3: null,
        },
        L: {
          1: null,
          2: null,
          3: null,
        },
      },
      red: {
        S: {
          1: null,
          2: null,
          3: null,
        },
        M: {
          1: null,
          2: null,
          3: null,
        },
        L: {
          1: null,
          2: null,
          3: null,
        },
      },
    },
    playerInformation: {},
    history: [],
  };
}

export function createBoardModule(board = createBoard(), updateCallback = () => {
}) {
  return {
    namespaced: true,
    state: () => board,
    getters: {
      cells(state) {
        const cells = createCells()

        for (const color in state.pieces) {
          for (const size in state.pieces[color]) {
            for (const piece in state.pieces[color][size]) {
              const target = state.pieces[color][size][piece];
              if (null !== target) {
                cells[target][size] = color;
              }
            }
          }
        }

        return cells;
      },
      nextAvailableColor(state) {
        for (const color in state.players) {
          if (null === state.players[color]) {
            return color;
          }
        }

        return null;
      },
      colors(state) {
        return Object.keys(state.players);
      },
      activeColors(state, getters) {
        return getters.colors.filter(color => {
          return !empty(state.players[color]);
        })
      },
      nextColorToPlay(state, getters) {
        if (0 === state.history.length) {
          return getters.activeColors[0];
        }
        const lastColor = [...state.history].pop().color;
        return clockwise(lastColor, getters.activeColors);
      },
      nbPlayers(state) {
        let nbPlayers = 0;
        for (const color in state.players) {
          if (null !== state.players[color]) {
            nbPlayers++;
          }
        }

        return nbPlayers;
      },
      isWaitingForPlayers(state, getters) {
        return empty(state.history) && getters.nbPlayers < 4;
      },
      isReadyToPlay(state, getters) {
        return getters.nbPlayers >= 2;
      },
      getUserById(state) {
        return userId => {
          return state.playerInformation[userId] ?? null;
        }
      },
      getUserByColor(state) {
        return color => {
          const userId = state.players[color];
          if (null === userId) {
            return null;
          }

          return state.playerInformation[userId] ?? null;
        }
      },
      getColorsByUserId(state) {
        return userId => {
          const colors = [];
          for (const color in state.players) {
            if (state.players[color] === userId) {
              colors.push(color);
            }
          }

          return colors;
        }
      },
      winningSequence(state, getters) {
        const cells = getters.cells;

        // Wins on same color on 1 cell
        for (const target in cells) {
          if (Object.prototype.hasOwnProperty.call(cells, target)) {
            const cell = cells[target];
            if (hasSameColor(cell)) {
              return {
                color: cell['S'],
                targets: [
                  {target, size: 'S'},
                  {target, size: 'M'},
                  {target, size: 'L'},
                ],
              }
            }
          }
        }

        // Wins on same color the whole line, same size
        for (const line of lines) {
          for (const size of sizes) {
            const colors = [];
            const targets = [];
            let color;
            let hasNull = false;
            for (const target of line) {
              color = cells[target][size];
              colors.push(color);
              targets.push(target);
              if (null === color) {
                hasNull = true;
              }
            }
            if (true === hasNull) {
              continue;
            }
            const areSameColors = colors[0] === color && colors[1] === color && colors[2] === color;
            if (areSameColors) {
              return {
                color,
                targets: [
                  {target: targets[0], size},
                  {target: targets[1], size},
                  {target: targets[2], size},
                ],
              }
            }
          }
        }

        // Wins on same color, ascending order
        for (const line of lines) {
          const first = cells[line[0]]['S'];
          const middle = cells[line[1]]['M'];
          const last = cells[line[2]]['L'];
          const colors = [first, middle, last];
          if (colors.includes(null)) {
            continue;
          }
          const areSameColors = first === middle && middle === last;
          if (areSameColors) {
            return {
              color: first,
              targets: [
                {target: line[0], size: 'S'},
                {target: line[1], size: 'M'},
                {target: line[2], size: 'L'},
              ],
            }
          }
        }

        // Wins on same color, descending order
        for (const line of lines) {
          const first = cells[line[0]]['L'];
          const middle = cells[line[1]]['M'];
          const last = cells[line[2]]['S'];
          const colors = [first, middle, last];
          if (colors.includes(null)) {
            continue;
          }
          const areSameColors = first === middle && middle === last;
          if (areSameColors) {
            return {
              color: first,
              targets: [
                {target: line[0], size: 'L'},
                {target: line[1], size: 'M'},
                {target: line[2], size: 'S'},
              ],
            }
          }
        }

        return null;
      }
    },
    mutations: {
      REPLACE_STATE(state, newState) {
        Object.assign(state, newState);
      },
      SET_PLAYER(state, {user, color}) {
        Vue.set(state.players, color, user.id);
        Vue.set(state.playerInformation, user.id, user);
      },
      MOVE_PIECE(state, move) {
        const {color, size, piece, target} = move;
        state.pieces[color][size][piece] = target;
        state.history.push(move);
      },
    },
    actions: {
      async UPDATE({commit}, data) {
        commit('REPLACE_STATE', data);
      },
      async JOIN({state, getters, commit}, {user, color}) {
        if (false === getters.colors.includes(color)) {
          throw Error('This color is not available.');
        }
        if (null !== state.players[color]) {
          throw Error('This color is already taken.');
        }
        await commit('SET_PLAYER', {user, color});
        updateCallback(state);
      },
      async PLAY({state, getters, commit}, move) {
        const {color, size, piece, target} = move;
        if (false === getters.isReadyToPlay) {
          throw Error('The board is not ready: waiting for players.');
        }
        if (false === colors.includes(color)) {
          throw Error('This color is not available.');
        }
        if (false === sizes.includes(size)) {
          throw Error('This size is not available.');
        }
        if (false === [1, 2, 3].includes(piece)) {
          throw Error('This piece is not available.');
        }
        if (false === targets.includes(target)) {
          throw Error('This target is not available.');
        }
        if (null !== state.pieces[color][size][piece]) {
          throw Error('This piece is already assigned.');
        }
        await commit('MOVE_PIECE', move);
        updateCallback(state);
      }
    }
  };
}

function clockwise(current, items) {
  if (null === current) {
    return items[0];
  }

  const index = items.indexOf(current);
  if (-1 === index || index === (items.length - 1)) {
    return items[0];
  }

  return items[index + 1];
}

function isFull(cell) {
  return null !== cell['S']
    && null !== cell['M']
    && null !== cell['L']
}

function hasSameColor(cell) {
  return isFull(cell)
    && cell['S'] === cell['M'] && cell['M'] === cell['L'];
}

/*function decodeTarget(target) {
  return target.split(/^([A-Z])([0-9])$/).filter(fragment => '' !== fragment);
}

function compareTargets(A, B) {
  if (null === B) {
    return -1;
  }
  const [colA, rowA] = decodeTarget(A);
  const [colB, rowB] = decodeTarget(B);

  if (rowA === rowB) {
    return compare(colA, colB);
  }

  return compare(rowA, rowB);
}*/

