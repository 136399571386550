<template>
  <div class="grid grid-cols-3 xl:gap-x-20">
    <div class="cell" v-for="(cell, target) in cells" :key="target">
      <cell>
        <template #large>
          <circle-slot v-if="null !== cell['L']" size="L" :fill="getCSSColor(target, 'L')" :selectable="false"/>
          <circle-slot v-else
                       size="L"
                       :selectable="isSelectable(target, 'L')"
                       :fill="nonPickableHexa"
                       @choose="pick(target, 'L')"/>
        </template>
        <template #medium>
          <circle-slot v-if="null !== cell['M']" size="M" :fill="getCSSColor(target, 'M')" :selectable="false"/>
          <circle-slot v-else
                       size="M"
                       :selectable="isSelectable(target, 'M')"
                       :fill="nonPickableHexa"
                       @choose="pick(target, 'M')"/>
        </template>
        <template #small>
          <circle-slot v-if="null !== cell['S']" size="S" :fill="getCSSColor(target, 'S')" :selectable="false"/>
          <circle-slot v-else
                       size="S"
                       :selectable="isSelectable(target, 'S')"
                       :fill="nonPickableHexa"
                       @choose="pick(target, 'S')"/>
        </template>
      </cell>
    </div>
  </div>
</template>

<script>
  import Cell from './Cell';
  import CircleSlot from './CircleSlot';
  import {GRAY, getHexaCode} from "@/store/board";
  import empty from 'is-empty';
  import Color from 'color';

  export default {
    components: {
      Cell,
      CircleSlot,
    },
    props: {
      board: {
        type: Object,
        required: true,
      },
      cells: {
        type: Object,
        required: true
      },
      pieces: {
        type: Object,
        required: false,
        default: () => ({
          S: {
            1: undefined,
            2: undefined,
            3: undefined,
          },
          M: {
            1: undefined,
            2: undefined,
            3: undefined,
          },
          L: {
            1: undefined,
            2: undefined,
            3: undefined,
          },
        })
      },
      playing: {
        type: Boolean,
        default: true,
      },
      winningSequence: {
        type: Object,
      }
    },
    data() {
      return {
        nonPickableHexa: GRAY,
      }
    },
    computed: {
      remaining() {
        const remaining = {
          L: 0,
          M: 0,
          S: 0,
        };

        for (const size in this.pieces) {
          for (const item in this.pieces[size]) {
            if (null === this.pieces[size][item]) {
              remaining[size]++;
            }
          }
        }

        return remaining;
      },
      selectable() {
        return {
          S: {
            1: this.remaining['S'] && null === this.pieces['S'][1],
            2: this.remaining['S'] && null === this.pieces['S'][2],
            3: this.remaining['S'] && null === this.pieces['S'][3],
          },
          M: {
            1: this.remaining['M'] && null === this.pieces['M'][1],
            2: this.remaining['M'] && null === this.pieces['M'][2],
            3: this.remaining['M'] && null === this.pieces['M'][3],
          },
          L: {
            1: this.remaining['L'] && null === this.pieces['L'][1],
            2: this.remaining['L'] && null === this.pieces['L'][2],
            3: this.remaining['L'] && null === this.pieces['L'][3],
          },
        };
      }
    },
    methods: {
      getCSSColor(target, size) {
        const colorAsString = this.cells[target][size];
        if (null === colorAsString) {
          return GRAY;
        }

        const hexaCode = getHexaCode(colorAsString);
        if (empty(this.winningSequence)) {
          return hexaCode;
        }

        const matchWinningSequence = (-1 !== this.winningSequence.targets.findIndex(item => target === item.target && size === item.size));
        return matchWinningSequence ? hexaCode : Color(hexaCode).fade(0.5).rgb();
      },
      pick(target, size) {
        if (false === this.playing) {
          return;
        }
        this.$emit('pick', {target, size});
      },
      isSelectable(target, size) {
        return this.playing
          && empty(this.winningSequence)
          && (this.selectable[size][1]
            || this.selectable[size][2]
            || this.selectable[size][3]);
      }
    }
  }
</script>

